<template>
    <b-card>
        <h4>Dış Anlaşma Oluştur</h4>
        <div class="row">
                <div class="col-md-3 mb-1">
                    <label class="form-label">Dış Anlaşma Adı</label>
                    <input type="text" class="form-control" v-model="Agreement.Name" placeholder="Dış Anlaşma Adı" />
                </div>
                <div class="col-md-9 mb-1">
                    <label class="form-label">Cari</label>
                    <v-select-list :loading="cariLoading" :options="cariList"  v-on:search="getCariList" v-model="cari" placeholder="Cari" label="CARI_ISIM"></v-select-list>
                </div>
        </div>
        <div class="border rounded p-75">
            <div class="col-12 mb-1">
                <button class="btn btn-flat-success" v-on:click="downloadSample">
                    <feather-icon icon="FileIcon"></feather-icon>
                    Örnek Excel
                </button>
            </div>
            <div class="row align-items-center">
                <div class="col-12 mb-1">
                    <label class="form-label">Excel İle Rota Ekle</label> 
                    <v-file-uploader :accept="['xlsx']" v-model="ExcelFile" valueParameter="File" ></v-file-uploader>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <button :disabled="ExcelFile.File == null" class="btn btn-primary text-nowrap waves-effect d-block" v-on:click="fileUpload" >
                        <feather-icon icon="PlusIcon" size="16" class="mr-1" ></feather-icon>Excel ile Rota Ekle
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-1" v-if="InvalidRoutes.length>0">
            <div class="col-12">
                <h3 class="font-weight-bolder">Geçersiz Bilgi İçeren Kayıtlar:</h3>
                <table class="table table-sm table-bordered align-middle ">
                    <thead>
                        <tr>
                            <th class="w-10 text-nowrap">Başlangıç Bölge Kodu</th>
                            <th class="w-10 text-nowrap">Bitiş Bölge Kodu</th>
                            <th class="w-10 text-nowrap">Araç Tipi Kodu</th>
                            <th class="w-10 text-nowrap">Ürün Tipi Kodu</th>
                            <th class="w-10 text-nowrap">Taşıma Tipi</th>
                            <th class="w-10 text-nowrap">Fiyat</th>
                            <th class="w-40 text-nowrap">Hatalar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(data) in InvalidRoutes">
                            <tr :key="data.Id">
                                <td v-if="data.BaslangicBolgeKodu != null">{{ data.BaslangicBolgeKodu }}</td>
                                <td v-if="data.BitisBolgeKodu != null">{{ data.BitisBolgeKodu }}</td>
                                <td v-if="data.AracTipiKodu != null">{{ data.AracTipiKodu }}</td>
                                <td class="text-nowrap" v-if="data.TasinanUrunStokKodu != null">{{data.TasinanUrunStokKodu}}</td>
                                <td v-if="data.TasimaDurumu != null">{{ data.TasimaDurumu }}</td>
                                <td v-if="data.Fiyat != null" class="text-right">{{ data.Fiyat }} ₺</td>
                                <td v-if="data.Errors.length>0" class="bg-light-danger p-50">
                                    <ul class="list-group">
                                        <li class="list-group-item" v-for="(x,index) in data.Errors" :key="index">{{x}}</li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br/>
        <div class="row mb-2" v-if="NullValueRoutes.length>0">
            <div class="col-12">
                <h3 class="font-weight-bolder">Boş Değer İçeren Kayıtlar:</h3>
                <table class="table table-sm table-bordered align-middle ">
                    <thead>
                        <tr>
                            <th class="w-18 text-nowrap">Başlangıç Bölge Kodu</th>
                            <th class="w-18 text-nowrap">Bitiş Bölge Kodu</th>
                            <th class="w-18 text-nowrap">Araç Tipi Kodu</th>
                            <th class="w-18 text-nowrap">Ürün Tipi Kodu</th>
                            <th class="w-18 text-nowrap">Taşıma Tipi</th>
                            <th class="w-10 text-nowrap">Fiyat</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <template v-for="(data) in NullValueRoutes">
                            <tr :key="data.Id">
                                <td v-if="data.BaslangicBolgeKodu != null">{{ data.BaslangicBolgeKodu }}</td>
                                <td v-if="data.BaslangicBolgeKodu == null" class="bg-light-danger">DEĞER YOK</td>
                                
                                <td  v-if="data.BitisBolgeKodu != null">{{ data.BitisBolgeKodu }}</td>
                                <td  v-if="data.BitisBolgeKodu == null" class="bg-light-danger">DEĞER YOK</td>

                                <td v-if="data.AracTipiKodu != null">{{ data.AracTipiKodu }}</td>
                                <td v-if="data.AracTipiKodu == null" class="bg-light-danger">DEĞER YOK</td>

                                <td class="text-nowrap" v-if="data.TasinanUrunStokKodu != null">{{data.TasinanUrunStokKodu}}</td>
                                <td class="text-nowrap bg-light-danger" v-if="data.TasinanUrunStokKodu == null">DEĞER YOK</td>

                                <td v-if="data.TasimaDurumu != null">{{ data.TasimaDurumu }}</td>
                                <td v-if="data.TasimaDurumu == null" class="bg-light-danger">DEĞER YOK</td>

                                <td v-if="data.Fiyat != null" class="text-right">{{ data.Fiyat }} ₺</td>
                                <td v-if="data.Fiyat == null" class="text-right bg-light-danger">DEĞER YOK</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-auto mb-1 mb-md-0">
                <button class="btn btn-primary text-nowrap waves-effect" v-on:click="createRoute" >
                    <feather-icon icon="PlusIcon" size="16" ></feather-icon>
                    Rota Ekle
                </button>
            </div>

            <b-modal id="create-route" title="Rota Oluştur" no-close-on-esc no-close-on-backdrop>
                <create-agreement-route v-model="agreementRoutePrice"></create-agreement-route>
                <template #modal-footer><v-executer :action="addRoute"></v-executer></template>
            </b-modal>

        </div>
        <div class="row mt-2" v-if="Agreement.AgreementRoutePrices.length>0">
            <div class="col-12">
                <h3 class="font-weight-bolder">Rotalar:</h3>
                <div class="table-responsive">
                    <table class="table table-sm table-bordered align-middle">
                        <thead>
                            <tr>
                                <th>İşlemler</th>
                                <th class="w-16">Başlangıç Bölgesi</th>
                                <th class="w-16">Bitiş Bölgesi</th>
                                <th class="w-16">Araç Tipi</th>
                                <th class="w-16 text-nowrap">Ürün Tipi</th>
                                <th class="w-16">Taşıma Tipi</th>
                                <th class="w-20">Fiyat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data,index) in Agreement.AgreementRoutePrices">
                                <tr :key="index">
                                    <td>
                                        <b-dropdown dropright size="sm" variant="light" no-caret>
                                            <template #button-content>
                                                <feather-icon class="mr-50" icon="MoreHorizontalIcon"></feather-icon>
                                                İşlemler
                                            </template>    
                                            <b-dropdown-item  variant="danger"  v-on:click="deleteRoute(index)">
                                                <feather-icon class="mr-50" icon="TrashIcon" size="16" ></feather-icon>
                                                Sil
                                            </b-dropdown-item> 
                                        </b-dropdown>
                                    </td>
                                    <td>{{ data.StartRegionName }}</td>
                                    <td>{{ data.EndRegionName }}</td>
                                    <td>{{ data.VehicleTypeName }}</td>
                                    <td class="text-nowrap">{{ data.StockName}}</td>
                                    <td>{{ data.VehicleTransportStatusName }}</td>
                                    <td class="text-right">{{ data.Price }} ₺</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row justify-content-end" v-if="Agreement.AgreementRoutePrices.length>0">
            <div class="col-auto mb-1 mb-md-0">
                <button class="btn btn-success text-nowrap waves-effect" v-on:click="createAgreement" >
                    <feather-icon icon="PlusIcon" size="16" ></feather-icon>
                    Anlaşmayı Kaydet
                </button>
            </div>
        </div>
    </b-card>
</template>
<script>
import {BCard,BFormCheckbox,BDropdown,BDropdownItem} from 'bootstrap-vue'
import {FirmApi} from '@/api/firm'
import VFileUploader from '@/components/v-file-uploader/VFileUploader.vue'
import CreateAgreementRoute from './components/CreateAgreementRoute.vue'
import { AgreementApi } from '@/api/agreement'
import { NetsisApi } from '@/api/netsis'
import Additional from '@/utilities/additional'
import { parse } from 'uuid'
export default {
    components:{
        BDropdown,
        BDropdownItem,
        BCard,
        BFormCheckbox,
        CreateAgreementRoute,
        VFileUploader
    },
    data(){
        return {
            ExcelFile:{
                File:null
            },
            InvalidRoutes:[],
            NullValueRoutes:[],
            Agreement:{
                Document:null,
                CariCode:null,
                CariName:null,
                VehicleTypeId:null,
                AgreementCode:null,
                Name:null,
                ExpireDate:null,
                StartDate:null,
                AgreementRoutePrices:[],
                AgreementPriceFactor:{
                    InflationAffectValue:null,
                    InflationAffectValueActive:true,
                    FuelAffectValue:null,
                    FuelAffectValueActive:true,
                    FuelAffectRateValue:null,
                    FuelAffectRateValueActive:true,
                    InflationAffectRateValueActive:true,
                    InflationAffectRateValue:0
                }
            },
            
            cariList:[],
            cariLoading:false,
            agreementRoutePrice:{
                VehicleTypeId:null,
                VehicleTypeName:null,
                StartRegionId:null,
                StartRegionName:null,
                EndRegionId:null,
                EndRegionName:null,
                Price:null,
                MaterialId:null,
                MaterialName:null,
                VehicleTransportStatus:null,
                VehicleTransportStatusName:null
            },
 
        }
    },
    mounted(){

    },
    methods:{
        deleteRoute(index){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem dış anlaşma rotasını silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    this.$delete(this.Agreement.AgreementRoutePrices,index)
                }
            })
        },
        downloadSample(){
            AgreementApi.DownloadSampleFile().then(res=>{
                Additional.DownloadFile(res.data, 'Dış Anlaşma Rota Şablonu.xlsx')
            })
        },
        addRoute(){
            this.Agreement.AgreementRoutePrices.push({
                VehicleTypeId: this.agreementRoutePrice.VehicleTypeId,
                VehicleTypeName: this.agreementRoutePrice.VehicleTypeName,
                StartRegionId: this.agreementRoutePrice.StartRegionId,
                StartRegionName: this.agreementRoutePrice.StartRegionName,
                EndRegionId: this.agreementRoutePrice.EndRegionId,
                EndRegionName: this.agreementRoutePrice.EndRegionName,
                Price:this.agreementRoutePrice.Price,
                StockCode:this.agreementRoutePrice.StockCode,
                StockName:this.agreementRoutePrice.StockName,
                VehicleTransportStatus: this.agreementRoutePrice.VehicleTransportStatus,
                VehicleTransportStatusName: this.agreementRoutePrice.VehicleTransportStatusName
            })
            console.log(this.Agreement.AgreementRoutePrices)
            this.$hideModal('create-route')
        },
        clearForm(){
            this.agreementRoutePrice.VehicleTypeId = null
            this.agreementRoutePrice.VehicleTypeName = null
            this.agreementRoutePrice.StartRegionId = null
            this.agreementRoutePrice.StartRegionName = null
            this.agreementRoutePrice.EndRegionId = null
            this.agreementRoutePrice.EndRegionName = null
            this.agreementRoutePrice.Price = null
            this.agreementRoutePrice.StockCode = null
            this.agreementRoutePrice.StockName = null
            this.agreementRoutePrice.VehicleTransportStatus = null
            this.agreementRoutePrice.VehicleTransportStatusName = null
        },
        createRoute(){
            this.clearForm()
            this.$showModal('create-route')
        },
        getCariList(data){
            if(data){
                this.cariLoading = true
                NetsisApi.CariListAll(data).then(res => {
                    this.cariList = res.data.Data
                    this.cariLoading = false
                }).catch(err=> {
                    console.error(err)
                    this.cariLoading = false
                })
            }
            
        },
        async createAgreement(){
            AgreementApi.Out.Create(this.Agreement).then(res => {
                this.$result(res)
                if(res.data.Success){
                    this.$router.push({name:'out-agreement'})
                }
            }).catch(err=> {
                console.error(err)
            })
        },
        async fileUpload(){
            this.Agreement.AgreementRoutePrices=[]
            this.NullValueRoutes=[]
            this.InvalidRoutes=[]
            let data = await Additional.ToFormData(this.ExcelFile)
            AgreementApi.UploadExcel(data).then(res => {
                this.$result(res)
                if(res.data.Success){
                    this.Agreement.AgreementRoutePrices.push(...res.data.Data.ValidRoutes)
                }else{
                    this.Agreement.AgreementRoutePrices.push(...res.data.Data.ValidRoutes)
                    this.InvalidRoutes.push(...res.data.Data.InvalidRoutes)
                    this.NullValueRoutes.push(...res.data.Data.HasNullValueRoutes)
                }
            }).catch(err=> {
                console.err(err)
            })
        }
    },
    computed:{
        cari:{
            get:function(){
                return this.cariList.find(s=>s.CARI_KOD==this.Agreement.CariCode)
            },
            set:function(value){
                this.Agreement.CariCode = value?.CARI_KOD || null
                this.Agreement.CariName = value?.CARI_ISIM || null
            }
        },
        
    }

}

</script>