import axios from 'axios'

export const IrsaliyeOperationsApi = {
    async Create(data){
        const response = await axios.post('/api/irsaliyeoperations/createirsaliyegroup' , data)
        return response
    },
    async Table(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroups/table',data)
        return response
    },
    async Delete(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroup/delete/'+data)
        return response
    },
    async SendToCustomer(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroup/sendtocustomer/'+data)
        return response
    },
    async Approve(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroup/approve/'+data)
        return response
    },
    async Complete(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroup/complete/'+data)
        return response
    },
    async Detail(data){
        const response = await axios.get('/api/irsaliyeoperations/irsaliyegroup/detail/'+data)
        return response
    },
    async CustomerDetail(data){
        const response = await axios.get('/api/irsaliyeoperations/irsaliyegroup/customer/detail/'+data)
        return response
    },
    async DetailStats(data){
        const response = await axios.get('/api/irsaliyeoperations/irsaliyegroup/detail/stats/'+data)
        return response
    },
    async SendIrsaliyeGroupToControl(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroups/sendtocontrol',data)
        return response
    },
    async SendCompletedIrsaliyeGroupToCustomer(data){ 
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroup/sendtocustomercompletedirsaliyegroup/'+data)
        return response
    },
    async ChangeStatusIrsaliyeGroupRouteChangeRequest(data){
        const response = await axios.post('/api/irsaliyeoperations/changestatusirsaliyegrouproutechangerequest',data)
        return response
    },
    async CalculatePrice(data){
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroup/calculateprice/'+data)
        return response
    },
    async GetChageRequestDetail(data){
        const response = await axios.get('/api/irsaliyeoperations/get/changerequestdetail/'+data)
        return response
    },
    async PricesSentToCustomer(data){ 
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroups/table/pricessenttocustomer',data)
        return response
    },
    async SetBillHasBeenInvoicedToIrsaliyeGroup(data){ 
        const response = await axios.post('/api/irsaliyeoperations/irsaliyegroups/setbillhasbeeninvoicedtoirsaliyegroup/'+data)
        return response
    },
    async Code(id){
        const response = await axios.get(`/api/irsaliyeoperations/irsaliyegroup/code/${id}`)
        return response
    },
    async CheckCode(id,code){
        const response = await axios.get(`/api/irsaliyeoperations/irsaliyegroup/code/check/${id}/${code}`)
        return response
    }
}