<template>
    <b-card no-body>

        <b-modal id="create-or-update" :title="editing ? 'Güncelle' : 'Oluştur'" no-close-on-esc no-close-on-backdrop>
            <user-create-or-update v-model="user"></user-create-or-update>
            <template #modal-footer><v-executer :loading="loading" :editing="editing" :action="createOrUpdate"></v-executer></template>
        </b-modal>

        <v-table    ref="table"
                    :add-callback="createNew"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server"></v-table>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { UserApi } from '@/api/user'
import UserCreateOrUpdate from './components/UserCreateOrUpdate.vue'
export default {
    components:{
        BCard,
        UserCreateOrUpdate
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Ad Soyad',key:'FullName',sortable:true, thClass:'w-20'},
                {name:'Rol',key:'RoleGroupName',sortable:true,sortKey:'RoleGroup.Name',thClass:'w-20'},
                {name:'E-Posta',key:'Email',sortable:true,thClass:'w-15'},
                {name:'Telefon',key:'PhoneNumber',sortable:true,thClass:'w-10'},
                {name:'Plasiyer Kodu',key:'SalesPersonCode',sortable:true,thClass:'w-5'},
                {name:'Aktif mı?',key:'Active',sortable:true,thClass:'w-10'},
                {name:'Onaylı mı?',key:'AccountConfirmed',sortable:true,thClass:'w-10'},
                {name:'Son Giriş Tarihi',key:'LastLoginDate',sortable:true,thClass:'w-10'},

            ],
            buttons:[
                { text:"Düzenle",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.edit },
                { text:"Deaktif Et", key:"deactive",icon:"UnlockIcon",variant:"danger",action:this.activate,condition:(item) => item.AccountConfirmed && item.Active },
                { text:"Aktif Et", key:"active",icon:"LockIcon",variant:"success",action:this.activate,condition:(item) => item.AccountConfirmed && !item.Active },
                { text:"Aktivasyon Bağlantısı Gönder", key:"confirm",icon:"SendIcon",variant:"info",action:this.confirm,condition:(item) => !item.AccountConfirmed },
                { text:"Sil",key:"delete",icon:"TrashIcon",variant:"danger",action:this.delete },
            ],
            user:{
                Id:null,
                Name: null,
                Surname: null,
                Email: null,
                PhoneNumber: null,
                SalesPersonCode: null,
                ConfirmationType:1,
                RoleGroupId: null,
                ResponsibleUserId: null
            }
        }
    },
    methods:{
        clearForm(){
            this.editing = false
            this.user.Id = null
            this.user.Name = null
            this.user.Surname = null
            this.user.Email = null
            this.user.PhoneNumber = null
            this.user.SalesPersonCode = null
            this.user.ConfirmationType = 1
            this.user.RoleGroupId = null
            this.user.ResponsibleUserId = null
        },
        provider(data){
            return UserApi.Table(data)
        },
        createNew(){
            this.clearForm()
            this.$showModal('create-or-update')
        },
        edit(item){
            this.clearForm()
            this.editing = true
            this.user.Id = item.Id
            this.user.Name = item.Name
            this.user.Surname = item.Surname
            this.user.Email = item.Email
            this.user.PhoneNumber = item.PhoneNumber
            this.user.SalesPersonCode = item.SalesPersonCode
            this.user.RoleGroupId = item.RoleGroupId
            this.user.ConfirmationType = item.ConfirmationType
            this.user.ResponsibleUserId = item.ResponsibleUserId
            this.$showModal('create-or-update')
        },
        activate(item){
            this.$refs.table.rowLoading(true)
            UserApi.Disable(item.Id).then(res=>{
                this.$result(res)
                this.$refs.table.rowLoading(false)
                if(res.data.Success){
                    this.$refs.table.refresh()
                }
            }).catch(err=>{
                this.$refs.table.rowLoading(false)
                this.$result(null,err)
            })
        },
        confirm(item){
            this.$refs.table.rowLoading(true)
            UserApi.ResendConfirmationCode(item.Id).then(res=>{
                this.$result(res)
                this.$refs.table.rowLoading(false)
                if(res.data.Success){
                    this.$refs.table.refresh()
                }
            }).catch(err=>{
                this.$refs.table.rowLoading(false)
                this.$result(null,err)
            })
        },
        createOrUpdate(){
            this.loading = true
            UserApi.CreateOrUpdate(this.editing,this.user).then(res=>{
                this.$result(res)
                this.loading = false
                if(res.data.Success){
                    this.$refs.table.refresh()
                    this.$hideModal('create-or-update')
                }
            }).catch(err=>{
                this.loading = false
                this.$result(null,err)
            })
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.FullName} adlı kullanıyı silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    UserApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.$refs.table.refresh()
                        }
                        this.$result(res)
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>