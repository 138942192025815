import axios from 'axios'

export const NetsisApi = {
    async CariList(data){
        const response = await axios.get(`/api/netsis/get/carilist/${data}`)
        return response
    },
    async CariListAll(data){
        const response = await axios.get(`/api/netsis/get/carilist/all/${data}`)
        return response
    },
    async Table(data){
        const response = await axios.post(`/api/netsis/get/caritable`,data)
        return response
    },
    async GetCariTypesByCari(data){
        const response = await axios.get(`/api/netsis/get/caritypesbycari/${data}`)
        return response
    },
    async UpdateCariTypes(data){
        const response = await axios.post(`/api/netsis/update/caritypes`,data)
        return response
    },
    async GetSeferList(data){
        const response = await axios.post(`/api/netsis/get/list/sefer`,data)
        return response
    },
    async GetSeferListReport(data){
        const response = await axios.post(`/api/netsis/get/list/sefer/report`,data)
        return response
    },
    async GetSeferListTodayReport(data){
        const response = await axios.post(`/api/netsis/get/list/sefer/report/today`,data)
        return response
    },
    async GetSeferListReportTodayExcel(){
        const response = await axios.get(`/api/netsis/get/list/sefer/report/today/excel`,{
            responseType: 'blob',
        })
        return response
    },
    async GetSeferListReportExcel(start,end){
        const response = await axios.get(`/api/netsis/get/list/sefer/report/excel/${start}/${end}`,{
            responseType: 'blob',
        })
        return response
    },
    async StokList(data){
        const response = await axios.get(`/api/netsis/get/list/stok/${data}`)
        return response
    },
    async CreateOrUpdateParameter(update,data){
        const response = await axios.post(update ? `/api/netsis/update/cariparameter`: `/api/netsis/create/cariparameter`,data)
        return response
    },
    async GetSubeList(){
        const response = await axios.get('/api/netsis/get/list/sube')
        return response
    },
    async GetAlisIrsaliye(data){
        const response = await axios.post('/api/netsis/get/alisirsaliye',data)
        return response
    },
    async UpdateAlisIrsaliyeEkAlans(data){
        const response = await axios.post('/api/netsis/update/alisirsaliye/ekalans',data)
        return response
    },
}