<template>
    <div class="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center">
        <b-card v-if="sending"  no-body class="p-2 d-flex flex-column align-items-center g-1">
            <b-spinner></b-spinner>
            Kod gönderiliyor...
        </b-card>
        <b-card v-else-if="!sending && !sended" no-body class="p-50">
            <div class="alert alert-danger mb-0">
                <div class="alert-body">Kod gönderilirken bir hata oluştu.</div>
            </div>
        </b-card>
        <b-card no-body class="p-2" v-else>
            <v-pin-code :disabled="going" v-model="code"></v-pin-code>
            <div class="mt-1 text-center">
                İrsaliye görüntüleme kodu tarafınıza gönderilmiştir.<br/> E-posta adresinizi kontrol ediniz.
            </div>
            <v-executer class="justify-content-center mt-1" :action="go" :loading="going">Gönder</v-executer>
        </b-card>
    </div>
</template>

<script>
import { IrsaliyeOperationsApi } from '@/api/irsaliye';
import { BCard, BSpinner } from 'bootstrap-vue';
import VPinCode from '@/components/VPinCode.vue';


export  default {
    components:{
        BCard,
        BSpinner,
        VPinCode
    },
    data(){
        return {
            sended:false,
            sending:false,
            code:'',
            going:false
        }
    },
    mounted(){
        this.getCode()
    },
    methods:{
        getCode(){
            const id = this.$route.params.id
            this.sending = true
            IrsaliyeOperationsApi.Code(id).then(res => {
                if(res.data.Success){
                    this.sended = true
                }else{
                    this.$result(res)
                }
                this.sending = false
            }).catch(err => {
                this.$result(null,err)
                this.sending = false
            })
        },
        go(){
            this.going = true
            IrsaliyeOperationsApi.CheckCode(this.$route.params.id,this.code).then(r => {
                if(!r.data.Success){
                    this.$result(r)
                }else{
                    this.$router.push({name:'waybill-detail-customer',params:{id:this.$route.params.id},query:{code:r.data.Data}})
                }
                this.going = false
            }).catch(err => {
                this.$result(null,err)
                this.going = false
            })
        }
    }
}
</script>