import store from '@/store'
import { SystemParameterApi } from '@/api/system-parameter';
import { AES, enc } from 'crypto-js';

export default{
    get parameters(){
        return store.state.parameters.parameters
    },
    set parameters(val){
        store.commit('parameters/setParameters', val);
    },
    loadParameters(){
        return new Promise((resolve)=>{
            SystemParameterApi.List().then(res=>{
                if(res.data.Success){
                    try {
                        const key = enc.Utf8.parse('8402859282324212')
                        const iv = enc.Utf8.parse('0292859222524126')
                        var data = AES.decrypt(res.data.Message,key,{iv}).toString(enc.Utf8)
                        var jData = JSON.parse(data)
                        this.parameters = jData?.data || []
                    } catch (error) {
                        console.log('Parameters load error.')
                    }
                }
                resolve(true)
            }).catch(()=>resolve(true))
        })
    },
    getParam(key){
        return this.parameters.find(p=>p.Key == key)?.Value || null 
    }
}