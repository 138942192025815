<template>
    <div class="input-wrapper d-flex align-items-center justify-content-center">
        <pincode-input :disabled="disabled" v-model="code" :length="6" placeholder="-"/>
    </div>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
export default {
    components:{
        PincodeInput
    },
    props:{
        value:{
            type:String,
            default:''
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        code:{
            get:function(){
                return this.value
            },
            set:function(value){
                this.$emit('input',value)
            }
        }
    }
}
</script>